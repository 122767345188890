<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            7. Первая помощь при кровотечении
          </v-card-title>
        </v-col>
        <v-col
          cols="12"
          class="pb-0"
        >
            <v-alert
              border="left"
              colored-border
              color="red"
              class="title font-weight-light pl-10"
              elevation="2"
            >
            <strong>Кровотечение </strong> - истечение крови из кровеносных сосудов в органы, ткани, полости организма или наружу.
          </v-alert>
        </v-col>
        <v-col cols="6">
          <v-card-title class="mb-10">
            Симптомы острой кровопотери:
          </v-card-title>
          <div class="d-flex flex-column justify-center align-center mt-16 mb-16">
            <div>
              <v-tooltip top color="red">
                <template v-slot:activator="{ on }">
                  <v-btn dark v-on="on" rounded class="mr-5 title pa-5 red"><v-icon>mdi-information-outline</v-icon></v-btn>
                </template>
                <span class="pa-5 subtitle-1">Резкая слабость, головокружение</span>
              </v-tooltip>
              <v-tooltip top color="red">
                <template v-slot:activator="{ on }">
                  <v-btn dark v-on="on" rounded class="mr-5 title pa-5 red"><v-icon>mdi-information-outline</v-icon></v-btn>
                </template>
                <span class="pa-5 subtitle-1">Жажда</span>
              </v-tooltip>
              <v-tooltip top color="red">
                <template v-slot:activator="{ on }">
                  <v-btn dark v-on="on" rounded class="mr-5 title pa-5 red"><v-icon>mdi-information-outline</v-icon></v-btn>
                </template>
                <span class="pa-5 subtitle-1">Мелькание «мушек» перед глазами, обморок при попытке встать</span>
              </v-tooltip>
              <v-tooltip top color="red">
                <template v-slot:activator="{ on }">
                  <v-btn dark v-on="on" rounded class="mr-5 title pa-5 red"><v-icon>mdi-information-outline</v-icon></v-btn>
                </template>
                <span class="pa-5 subtitle-1">Бледная, влажная и холодная кожа</span>
              </v-tooltip>
              <v-tooltip top color="red">
                <template v-slot:activator="{ on }">
                  <v-btn dark v-on="on" rounded class="mr-5 title pa-5 red"><v-icon>mdi-information-outline</v-icon></v-btn>
                </template>
                <span class="pa-5 subtitle-1">Учащенное сердцебиение, частое дыхание</span>
              </v-tooltip>
            </div>
          <div style="width:100%" class="d-flex mt-16">
            <v-img
              lazy-src="img/material/blood.svg"
              width="100%"
              contain
              src="img/material/blood.svg"
              class="d-flex ptm-img"
            ></v-img>
          </div>
          <v-sheet flat class="d-flex justify-center mx-auto pb-0 mt-2">
            <v-card class="d-flex justify-center" flat>
              <v-icon color="red" size="30">mdi-cursor-pointer</v-icon>
              <v-card-title class="font-weight-light">
                Наводите на кнопки для изучения информации
              </v-card-title>
            </v-card>
          </v-sheet>
          <div style="width:100%" class="d-flex flex-column">
          <v-dialog
            v-model="dialog1"
            width="80%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red"
                  class="mt-3"
                  v-bind="attrs"
                  v-on="on"
                  dark
                  block
                  outlined
                  height="70"
                  >
                Виды кровотечений
              </v-btn>
            </template>
            <v-card>
              <Modal1></Modal1>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog1 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialog2"
            width="70%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red"
                  class="mt-3"
                  v-bind="attrs"
                  v-on="on"
                  dark
                  block
                  outlined
                  height="70"
                  >
                Способы остановки кровотечений
              </v-btn>
            </template>
            <v-card>
              <Modal2></Modal2>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog2 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialog3"
            width="50%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red"
                  class="mt-3"
                  v-bind="attrs"
                  v-on="on"
                  dark
                  block
                  outlined
                  height="70"
                  >
                Запрещено
              </v-btn>
            </template>
            <v-card>
              <Modal3></Modal3>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog3 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          </div>
          </div>
        </v-col>
        <v-col cols="6">
          <v-card-title class="mb-10">
            Общие правила оказания помощи:
          </v-card-title>
          <v-card
          class="pa-1 d-flex justify-center align-center"
          full-width
          flat

          >
            <v-icon color="red" size="100">mdi-numeric-1</v-icon>
            <v-card-text class="title font-weight-light">
              Обеспечить безопасные условия для оказания первой помощи;
            </v-card-text>
          </v-card>
          <v-card
          class="pa-1 d-flex justify-center align-center"
          full-width
          flat

          >
            <v-icon color="red" size="100">mdi-numeric-2</v-icon>
            <v-card-text class="title font-weight-light">
              Убедиться в наличии признаков жизни у пострадавшего;
            </v-card-text>
          </v-card>
          <v-card
          class="pa-1 d-flex justify-center align-center"
          full-width
          flat

          >
            <v-icon color="red" size="100">mdi-numeric-3</v-icon>
            <v-card-text class="title font-weight-light">
              Определить вид кровотечения;
            </v-card-text>
          </v-card>
          <v-card
          class="pa-1 d-flex justify-center align-center"
          full-width
          flat

          >
            <v-icon color="red" size="100">mdi-numeric-4</v-icon>
            <v-card-text class="title font-weight-light">
              Остановить кровотечение;
            </v-card-text>
          </v-card>
          <v-card
          class="pa-1 d-flex justify-center align-center"
          full-width
          flat

          >
            <v-icon color="red" size="100">mdi-numeric-5</v-icon>
            <v-card-text class="title font-weight-light">
              Немедленно доставить пострадавшего в медицинское учреждение.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <Hint></Hint>
    </v-container>
  </div>
</template>
<script>
import Modal1 from '@/components/Page50/Modal1.vue'
import Modal2 from '@/components/Page50/Modal2.vue'
import Modal3 from '@/components/Page50/Modal3.vue'
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    dialog1: null,
    dialog2: null,
    dialog3: null
  }),
  components: {
    Modal1,
    Modal2,
    Modal3,
    Hint
  }
}
</script>
<style lang="less" scoped>
.ptm-img-rel {
  position: relative;
}
.ptm-img-man-2-item-1 {
  position: absolute;
  top: 0%;
  right: 15%;
}
.ptm-img-man-2-item-2 {
  position: absolute;
  top: 20%;
  right: 15%;
}
.ptm-img-man-2-item-3 {
  position: absolute;
  top: 40%;
  right: 15%;
}
.ptm-img-man-2-item-4 {
  position: absolute;
  top: 60%;
  right: 15%;
}
.ptm-img-man-2-item-5 {
  position: absolute;
  top: 80%;
  right: 15%;
}
</style>
