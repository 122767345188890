<template>
  <div class="noselect">
    <v-card-title>
      Виды кровотечений:
    </v-card-title>
    <v-container fluid class="px-10">
      <v-row>
        <v-col cols="4">
          <v-card
            class="mx-auto d-flex flex-column justify-center align-center"
          >
            <v-img
              lazy-src="img/material/blood-1.png"
              width="400"
              height="300"
              src="img/material/blood-1.png"
              class="ma-5"
              contain
            ></v-img>
            <v-card-actions>
              <v-btn
                text
                color="red"
                @click="reveal2 = true"
              >
                Капиллярное
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="reveal2"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%;"
              >
                <v-card-text class="pb-0">
                  <p class="title text--primary">
                    Капиллярное:
                  </p>
                  <p class="title font-weight-light">Наблюдается при ссадинах, порезах, царапинах</p>
                  <p class="title font-weight-light">Непосредственной угрозы для жизни не представляет</p>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-btn
                    text
                    color="red"
                    @click="reveal2 = false"
                  >
                    Закрыть
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card
            class="mx-auto d-flex flex-column justify-center align-center"
          >
            <v-img
              lazy-src="img/material/blood-2.png"
              width="400"
              height="300"
              src="img/material/blood-2.png"
              class="ma-5"
              contain
            ></v-img>
            <v-card-actions>
              <v-btn
                text
                color="red"
                @click="reveal3 = true"
              >
                Венозное
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="reveal3"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%;"
              >
                <v-card-text class="pb-0">
                  <p class="title text--primary">
                    Венозное:
                  </p>
                  <p class="title font-weight-light">Вытекает «ручьем» и не пульсирует</p>
                  <p class="title font-weight-light">Кровь темно-вишневая</p>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-btn
                    text
                    color="red"
                    @click="reveal3 = false"
                  >
                    Закрыть
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-col>
                <v-col cols="4">
          <v-card
            class="mx-auto d-flex flex-column justify-center align-center"
          >
            <v-img
              lazy-src="img/material/blood-3.png"
              width="300"
              src="img/material/blood-3.png"
              class="ma-5"
              height="300"
              contain
            ></v-img>
            <v-card-actions>
              <v-btn
                text
                color="red"
                @click="reveal1 = true"
              >
                Артериальное
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="reveal1"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%;"
              >
                <v-card-text class="pb-0">
                  <p class="title text--primary">
                    Артериальное:
                  </p>
                  <p class="title font-weight-light">Пульсирующая алая струя крови, быстро пропитывающая одежду.</p>
                  <p class="title font-weight-light">Наиболее опасны из-за большой потери крови за короткое время.</p>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-btn
                    text
                    color="red"
                    @click="reveal1 = false"
                  >
                    Закрыть
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-col>
        <Hint></Hint>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    reveal1: false,
    reveal2: false,
    reveal3: false
  }),
  components: {
    Hint
  }
}
</script>
<style lang="less" scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
