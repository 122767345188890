<template>
  <div class="pa-10 noselect">
    <v-card-title>
      ЗАПРЕЩЕНО:
    </v-card-title>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card flat class="d-flex justify-center align-center mb-10">
            <v-img
              lazy-src="img/material/stopwatch.svg"
              height="100%"
              width="10%"
              contain
              src="img/material/stopwatch.svg"
            >
            </v-img>
            <v-card-text class="title font-weight-light">
             Оставлять жгут больше разрешенного времени, так как может наступить омертвление тканей;
            </v-card-text>
          </v-card>

          <v-card flat class="d-flex justify-center align-center mb-10">
            <v-img
              lazy-src="img/material/elastic-band.svg"
              max-height="100%"
              max-width="10%"
              contain
              src="img/material/elastic-band.svg"
            >
            </v-img>
            <v-card-text class="title font-weight-light">
             Накладывать жгут на голое тело;
            </v-card-text>
          </v-card>
          <v-card flat class="d-flex justify-center align-center mb-10">
            <v-img
              lazy-src="img/material/hand-blood.svg"
              max-height="100%"
              max-width="12%"
              contain
              src="img/material/hand-blood.svg"
            >
            </v-img>
            <v-card-text class="title font-weight-light">
             Промывать рану или извлекать из нее мелкие предметы;
            </v-card-text>
          </v-card>
          <v-card flat class="d-flex justify-center align-center mb-10">
            <v-img
              lazy-src="img/material/sling.svg"
              max-height="100%"
              max-width="12%"
              contain
              src="img/material/sling.svg"
            >
            </v-img>
            <v-card-text class="title font-weight-light mb-5">
             Закрывать жгут повязкой или одеждой, он должен быть на виду;
            </v-card-text>
          </v-card>
          <v-card flat class="d-flex justify-center align-center ">
            <v-img
              lazy-src="img/material/blood-2.svg"
              max-height="100%"
              max-width="12%"
              contain
              src="img/material/blood-2.svg"
            >
            </v-img>
            <v-card-text class="title font-weight-light mb-5">
             Удалять сгустки крови и тромбы, иначе может открыться кровотечение.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data: () => ({
    //
  })
}
</script>
