<template>
  <div height="600" class="noselect">
    <v-card-title>
      Способы остановки кровотечений:
    </v-card-title>
    <v-container fluid>
      <v-row>
        <v-col cols="5">
          <v-tabs active-class="ptm-act-tab-class" grow v-model="tab1" vertical color="red">
            <v-tab class="justify-start">Прямое давление на рану</v-tab>
            <v-tab class="justify-start">Наложение давящей повязки</v-tab>
            <v-tab class="justify-start">Пальцевое прижатие артерии</v-tab>
            <v-tab class="justify-start">Максимальное сгибание конечности в суставе</v-tab>
            <v-tab class="justify-start">Наложение жгута</v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="7">
          <v-tabs-items vertical v-model="tab1">
            <v-tab-item>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-1</v-icon>
                Закрыть рану стерильными салфетками или бинтами;
            </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-2</v-icon>
                Надавить на рану рукой с достаточной силой.
            </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-1</v-icon>
                Закрыть рану стерильными салфетками или бинтами;
            </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-2</v-icon>
                Уложить валик из бинта;
            </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-3</v-icon>
                Туго перебинтовать и закрепить повязку;
            </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-4</v-icon>
                Если повязка пропиталась кровью, наложите поверх несколько стерильных салфеток и еще раз туго перебинтуйте.
            </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-1</v-icon>
                Нажать большим пальцем руки или 4 пальцами одновременно на артерию выше раны;
            </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-2</v-icon>
                Прижимать необходимо с достаточной силой, т.к. артериальные кровотечения интенсивные.
            </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-1</v-icon>
                В область сустава вложить валик или 1-2 бинта;
            </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-2</v-icon>
                Согнуть конечность;
            </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-3</v-icon>
                Зафиксировать положение руками, бинтом или подручными средствами.
            </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-1</v-icon>
                Перед наложением жгут заводят за конечность и растягивают;
            </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-2</v-icon>
                Жгут накладывают между раной и сердцем, максимально близко к ране;
            </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-3</v-icon>
                Накладывают поверх одежды или повязки;
            </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="50" color="red">mdi-numeric-4</v-icon>
                Оставить записку с указанием времени наложения жгута.
            </v-card-text>
            <v-card-title>
              Максимальное время наложения жгута:
            </v-card-title>
              <v-card-text class="title font-weight-light mb-0 pb-0 pl-10">
               - летом – 1 час;
            </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0 pl-10">
              -  зимой – 30 минут.
            </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data: () => ({
    tab1: null
  })
}
</script>
<style lang="less" scoped>
.ptm-act-tab-class {
  background-color: #FFCDD2;
  }
</style>
