<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            8. Первая помощь при переломах костей
          </v-card-title>
        </v-col>
        <v-col
          cols="12"
          class="pb-0"
        >
            <v-alert
              border="left"
              colored-border
              color="red"
              class="title font-weight-light pl-10"
              elevation="2"
            >
            <strong>Перелом </strong> - нарушение целостности кости, в результате травмы (падения, удара).
          </v-alert>
        </v-col>
        <v-col cols="6">
          <v-card-title class="mb-10">
            Симптомы:
          </v-card-title>
          <div class="d-flex justify-center align-center">
            <div style="width:70%" class="">
            <v-img
              lazy-src="img/material/broken-hand.svg"
              width="70%"
              contain
              src="img/material/broken-hand.svg"
              class="d-flex ptm-img"
            ></v-img>
          </div>
            <div class="d-flex flex-column">
              <v-tooltip right color="red">
                <template v-slot:activator="{ on }">
                  <v-btn dark v-on="on" rounded class="mb-5 title pa-5 red"><v-icon>mdi-information-outline</v-icon></v-btn>
                </template>
                <span class="pa-5 subtitle-1">Резкая боль при движениях и нагрузках</span>
              </v-tooltip>
              <v-tooltip right color="red">
                <template v-slot:activator="{ on }">
                  <v-btn dark v-on="on" rounded class="mb-5 title pa-5 red"><v-icon>mdi-information-outline</v-icon></v-btn>
                </template>
                <span class="pa-5 subtitle-1">Деформация конечности</span>
              </v-tooltip>
              <v-tooltip right color="red">
                <template v-slot:activator="{ on }">
                  <v-btn dark v-on="on" rounded class="mb-5 title pa-5 red"><v-icon>mdi-information-outline</v-icon></v-btn>
                </template>
                <span class="pa-5 subtitle-1">Неестественное положение конечности</span>
              </v-tooltip>
              <v-tooltip right color="red">
                <template v-slot:activator="{ on }">
                  <v-btn dark v-on="on" rounded class="mb-5 title pa-5 red"><v-icon>mdi-information-outline</v-icon></v-btn>
                </template>
                <span class="pa-5 subtitle-1">Отек и кровоизлияние</span>
              </v-tooltip>
            </div>
          </div>
          <div style="width:100%" class="d-flex flex-column">
            <v-sheet flat class="d-flex justify-center mx-auto pb-0 mt-2">
              <v-card class="d-flex justify-center" flat>
                <v-icon color="red" size="30">mdi-cursor-pointer</v-icon>
                <v-card-title class="font-weight-light">
                  Наводите на кнопки для изучения информации
                </v-card-title>
              </v-card>
            </v-sheet>
            </div>
        </v-col>
        <v-col cols="6">
          <v-card-title>
            Действия:
          </v-card-title>
          <v-card class="ptm-card-tab" flat>
            <Hint></Hint>
            <v-tabs
            v-model="tab"
            grow
            color="red"
            slider-color="white"
            >
              <v-tab class="pa-5">
                <v-card-title>
                  <v-icon size="40">mdi-numeric-1-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="40">mdi-numeric-2-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="40">mdi-numeric-3-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="40">mdi-numeric-4-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="40">mdi-numeric-5-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="40">mdi-numeric-6-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="40">mdi-numeric-7-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
            </v-tabs>

            <v-tabs-items height="200" class="mb-10" v-model="tab">
              <v-tab-item>
                <v-card-text class="title font-weight-light mt-10">
                  Убедиться в отсутствии опасности для себя и пострадавшего
                </v-card-text>
              </v-tab-item>
              <v-tab-item >
                <v-card-text class="title font-weight-light mt-10">
                  Вызвать скорую помощь
                </v-card-text>
              </v-tab-item>
              <v-tab-item >
                <v-card-text class="title font-weight-light mt-10">
                  Обеспечить неподвижность травмированного места
                </v-card-text>
              </v-tab-item>
              <v-tab-item >
                <v-card-text class="title font-weight-light mt-10">
                  <p>Кровотечение при открытом переломе следует остановить, наложив жгут или повязку.</p>
                  <p>Наложить на рану антисептическую повязку или закрыть хлопчатобумажной тканью</p>
                </v-card-text>
              </v-tab-item>
              <v-tab-item >
                <v-card-text class="title font-weight-light mt-10">
                  Принять меры по предупреждению болевого шока
                </v-card-text>
              </v-tab-item>
              <v-tab-item >
                <v-card-text class="title font-weight-light mt-10">
                  Наложить шину из подручных материалов
                </v-card-text>
              </v-tab-item>
              <v-tab-item>
                <v-card-text class="title font-weight-light mt-10">
                  Быстро доставить пострадавшего в медицинское учреждение.
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </v-card>

        </v-col>
        <v-col cols="6">
          <v-card flat class="pa-5 mb-5">
            <v-card-title class="red--text pt-0">
              ЗАПРЕЩЕНО:
            </v-card-title>
            <v-card-text class="title font-weight-light mb-0 pb-0">
              <v-icon color="red">mdi-alert-outline</v-icon>
              давать еду или питье, за исключением случаев профилактики болевого шока;
            </v-card-text>
            <v-card-text class="title font-weight-light mb-0 pb-0">
              <v-icon color="red">mdi-alert-outline</v-icon>
              без необходимости перемещать пострадавшего, менять положение травмированной конечности;
            </v-card-text>
            <v-card-text class="title font-weight-light mb-0 pb-0">
              <v-icon color="red">mdi-alert-outline</v-icon>
              пытаться составлять обломки костей или вправить кость самостоятельно;
            </v-card-text>
            <v-card-text class="title font-weight-light mb-0 pb-0">
              <v-icon color="red">mdi-alert-outline</v-icon>
              фиксировать шину в месте, где выступает кость;
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card flat class="pa-5 mb-5">
            <v-card-text class="title font-weight-light mb-0 pb-0">
              <v-icon color="red">mdi-alert-outline</v-icon>
              доставать обломки кости из раны;
            </v-card-text>
            <v-card-text class="title font-weight-light mb-0 pb-0">
              <v-icon color="red">mdi-alert-outline</v-icon>
              прикладывать к месту перелома грелку;
            </v-card-text>
            <v-card-text class="title font-weight-light mb-0 pb-0">
              <v-icon color="red">mdi-alert-outline</v-icon>
              без необходимости снимать одежду и обуви (в месте перелома одежду и обувь вырезать);
            </v-card-text>
            <v-card-text class="title font-weight-light mb-0 pb-0">
              <v-icon color="red">mdi-alert-outline</v-icon>
              заливать в рану антисептики (йод, спирт), это вызовет болевой шок;
            </v-card-text>
            <v-card-text class="title font-weight-light mb-0 pb-0">
              <v-icon color="red">mdi-alert-outline</v-icon>
              использовать загрязненные материалы для обработки раны и повязки.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    tab: null,
    dialog1: null
  }),
  components: {
    Hint
  }
}
</script>
<style lang="less" scoped>
.ptm-card-tab {
  height: 300px;
}
</style>
